/**
 * Texts for Training page
 */
export const trainingPageText = {
    title: 'Oplæring – CamAPS FX',
    titleMeta: 'Vi sørger for gratis onlineoplæring til CamAPS FX-brugere',
    descriptionMeta: 'Cambridge Diabetes Education Programme udbyder gratis certificeret onlineoplæring til mennesker med type 1-diabetes, forældre og værger, skolepersonale og behandlere.',
    keywords:
        'camps træning, camps fx træning, ' ,
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'da_dk',

    trainingHeader: 'Certificeret oplæring',
    trainingHeader_2: 'For brugere og behandlere',
    trainingHeader_3: 'Brugeruddannelse',
    trainingHeaderText: 'GRATIS CamAPS FX-onlineoplæring leveret af Cambridge Diabetes Education Programme (CDEP).',
    websiteLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    websiteLinkHeading: 'CDEP-oplæring',
    getTrained: 'Bliv oplært',

    trainingHeaderIat: "CamAPS FX træning",
    trainingHeaderTextIat: "Fuldfør den essentielle træning i appen, før du bruger CamAPS FX-appen.",
    trainingSubHeaderOneIat: "Kom godt i gang",
    trainingSubTextOneIat: "Download CamAPS FX-appen. <br>Opret en konto for at starte træningen.",
    trainingSubHeaderTwoIat: "Brug for hjælp til at installere appen?",
    trainingParaTwoStartIat: "Besøg vores",
    trainingParaTwoEndIat: "sektion for vejledning.",
    websiteLinkHeadingSupportIat: "Support",
    trainingSubHeaderThreeIat: "Yderligere ressourcer",
    animationsYpsoPumpText: "Animationer",
    animationsAndText: "og",
    animationsDanaText: "Animationer (Dana-pumpen)",
    animationsFurtherText: "Lær gennem trin-for-trin visuelle guider.",
    webinarsText: "Webinarer",
    webinarsFurtherText: "Se ekspertledede sessioner om brug af appen.",
    websiteAnimationsYpsoPumpLink: "https://www.camdiabtraining.com/view/27/mylife-YpsoPump-users.htm",
    websiteAnimationsDanaLink: "https://www.camdiabtraining.com/view/25/Dana-insulin-pump-users.htm",
    websiteWebinarsLink: "https://www.camdiabtraining.com/view/20/Webinars.htm"

};
