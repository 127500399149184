/**
 * Texts for Training page
 */
export const trainingPageText = {
    title: 'Opplæring – CamAPS FX',
    titleMeta: 'Vi tilbyr gratis nettbasert opplæring for CamAPS FX brukere',
    descriptionMeta: 'Cambridge Diabetes Education Programme tilbyr gratis nettbasert sertifisert opplæring for personer med diabetes type 1, foresatte, skolepersonell og helsepersonell.',
    keywords:
        'camaps training, camaps fx training,  ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'nb_no',

    trainingHeader: 'Sertifisert opplæring',
    trainingHeader_2: 'For brukere og helsepersonell',
    trainingHeader_3: 'Brukeropplæring',
    trainingHeaderText: 'GRATIS nettbasert opplæring i CamAPS FX levert av Cambridge Diabetes Education Programme (CDEP).',
    websiteLink: 'https://camdiabtraining.com',
    websiteLinkHeading: 'CDEP-opplæring',
    getTrained: 'Få opplæring',

    trainingHeaderIat: "CamAPS FX-trening",
    trainingHeaderTextIat: "Fullfør den essensielle opplæringen i appen før du bruker CamAPS FX-appen.",
    trainingSubHeaderOneIat: "Kom i gang",
    trainingSubTextOneIat: "Last ned CamAPS FX-appen. <br>Opprett en konto for å starte opplæringen.",
    trainingSubHeaderTwoIat: "Trenger du hjelp med å installere appen?",
    trainingParaTwoStartIat: "Besøk vår",
    trainingParaTwoEndIat: "seksjon for veiledning.",
    websiteLinkHeadingSupportIat: "Support",
    trainingSubHeaderThreeIat: "Ytterligere ressurser",
    animationsYpsoPumpText: "Animasjoner",
    animationsAndText: "og",
    animationsDanaText: "Animasjoner (Dana-pumpe)",
    animationsFurtherText: "Lær gjennom trinnvise visuelle forklaringer.",
    webinarsText: "Webinarer",
    webinarsFurtherText: "Se ekspertledede sesjoner om bruk av appen.",
    websiteAnimationsYpsoPumpLink: "https://www.camdiabtraining.com/view/27/mylife-YpsoPump-users.htm",
    websiteAnimationsDanaLink: "https://www.camdiabtraining.com/view/25/Dana-insulin-pump-users.htm",
    websiteWebinarsLink: "https://www.camdiabtraining.com/view/20/Webinars.htm"

};
