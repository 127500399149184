/**
 * Texts for footer
 */
export const footerText = {
    txtFAQs: 'Często zadawane pytania',
    txtSupport: 'Wsparcie',
    txtHistory: 'Historia',
    txtTrainingHtml: 'https://www.camdiabtraining.com/account-sign-in.html',
    txtTraining: 'Szkolenie online',
    txtClinic: 'CamAPS FX Clinic',
    terms: 'Regulamin',
    privacy: 'Polityka prywatności',
    txtFooterBottom: '<h4><b>To jest wyrób medyczny. Używaj go zgodnie z instrukcją używania lub etykietą.</b> </h4>' +
        'CamAPS to zastrzeżony znak towarowy firmy CamDiab Ltd. Pozostałe znaki towarowe należą do ich odpowiednich właścicieli. Nie wolno stosować znaku towarowego CamDiab, nazwy handlowej ani szaty graficznej z niniejszej strony internetowej bez uprzedniego pisemnego upoważnienia firmy CamDiab, z wyjątkiem sytuacji, gdy służy to identyfikacji produktu lub usług firmy. Zdjęcia produktu przedstawiono wyłącznie w celach poglądowych.',
    rightsReserved: 'CamDiab Ltd. Wszelkie prawa zastrzeżone.',

    statusPageFooterText: 'Strona stanu',
    statusPageFooterLink: 'https://camdiabltd.statuspage.io/',

    sscpPageFooterText: 'CamAPS FX Informacje o bezpieczeństwie (SSCP)',
    sscpPageFooterHtml: 'https://s3.eu-west-1.amazonaws.com/camdiab.user.manual/APCam-FX(FX)-D47_SSP_PL.pdf',
};
