/**
 * Texts for footer
 */
export const footerText = {
    txtFAQs: 'Ofte stillede spørgsmål (FAQ)',
    txtSupport: 'Support',
    txtHistory: 'Historie',
    txtTrainingHtml: 'https://www.camdiabtraining.com/account-sign-in.html',
    txtTraining: 'Onlineoplæring',
    txtClinic: 'CamAPS FX-klinik',
    terms: 'Vilkår og betingelser',
    privacy: 'Databeskyttelsespolitik',
    txtFooterBottom: 'CamAPS er et registreret varemærke tilhørende CamDiab Ltd. Andre varemærker tilhører de respektive ejere. CamDiab-varemærket, -handelsnavnet eller -udstyret på denne side må ikke anvendes på nogen måde uden forudgående skriftlig tilladelse fra CamDiab, undtagen for at identificere virksomhedens produkt eller tjenester. Produktbillederne er kun tænkt som illustration.',
    rightsReserved: 'CamDiab Ltd. Alle rettigheder forbeholdes.',

    statusPageFooterText: 'Status side',
    statusPageFooterLink: 'https://camdiabltd.statuspage.io/',

    sscpPageFooterText: 'CamAPS FX Sikkerhedsoplysninger (SSCP)',
    sscpPageFooterHtml: 'https://s3.eu-west-1.amazonaws.com/camdiab.user.manual/APCam-FX(FX)-D47_SSP_DA.pdf',
};
