/**
 * Texts for header
 */
export const headerText = {
    cookiesIntroText: 'Soubory cookie používáme pouze ke sledování návštěv na našem webu, neukládáme žádné osobní údaje.',
    cookiesAccept: 'Povolit soubory cookie',
    cookiesDeny: 'Zakázat soubory cookie',
    tabFAQs: 'Často kladené otázky',
    tabSupport: 'Podpora',
    tabHistory: 'Historie',
    tabReviews: 'Hodnocení',
    tabTraining: 'Školení',
    tabHcpTraining: '',
    // tabTraining: 'Uživatelské školení',
    // tabHcpTraining: 'Zdravotnické školení',
    // tabOrders: 'Objednávky',
    tabOrders: '',
    tabNotifications: 'Oznámení',
};
