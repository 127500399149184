/**
 * Texts for Notifications page
 */
export const notificationsPageText = {
    title: 'Notificaciones recientes de la aplicación',
    titleMeta: 'Mostrando notificaciones recientes en la aplicación CamAPS FX',
    descriptionMeta: 'Aquí está el texto de las notificaciones recientes en la aplicación con respecto a las actualizaciones de la aplicación, avisos de seguridad, actualizaciones del manual del usuario',
    keywords: 'Notificaciones' ,
    localeMeta: 'es_es',

    heading: 'Notificaciones recientes de la aplicación',
    subheading: '',

         header_39:'',
    body_39:'',

         header_38:'',
    body_38:'',

         header_37:'Descontinuación de la versión antigua de la aplicación (solo Ypsopump)',
    body_37:'Por favor, actualice su aplicación a la última versión disponible en Google Play. La versión actual dejará de funcionar el 7 de febrero de 2025.',

         header_36:'Actualización de la aplicación 06/01/2025',
    body_36:'Estimado usuario de mylife CamAPS FX, Una nueva versión de la aplicación estará lista en las próximas 48 horas. Para elegir tu hora preferida para la actualización, desactiva la actualización automática para CamAPS FX en la Google Play Store. Si tienes alguna pregunta, por favor contacta al servicio al cliente de Ypsomed.',
	
	header_35:'OS 8 & 9 29/12/2024',
    body_35:'Por razones de ciberseguridad, a partir del 1 de enero de 2025, mylife CamAPS FX solo será compatible con teléfonos compatibles con sensores que ejecuten sistemas operativos Android 10 y superiores. Los usuarios de sistemas operativos por debajo de OS 10, ya no podrán actualizar o reinstalar mylife CamAPS FX después de esta fecha. Recordatorio, el sistema aún no es compatible con OS15',
	
	header_34:'',
    body_34:'',
	
	header_33:'Avviso di Manutenzione Programmata 05/12/2024',
    body_33:'Debido al mantenimiento programado de la nube del servicio CamAPS FX, la funcionalidad Companion no estará disponible el 7 de diciembre a partir de las 10:00 GMT durante aproximadamente tres horas. Pedimos disculpas por cualquier inconveniente que esto pueda causar..',
	
	header_32:'Actualización del manual de usuario 28/11/2024',
    body_32:'Existe una versión actualizada del manual de usuario.',
	
	header_31:'Actualización de la aplicación 17/11/2024',
    body_31:'Estimado usuario de mylife CamAPS FX, Una nueva versión de la aplicación estará lista en las próximas 48 horas. Para elegir tu hora preferida para la actualización, desactiva la actualización automática para CamAPS FX en la Google Play Store. Si tienes alguna pregunta, por favor contacta al servicio al cliente de Ypsomed.',
	
	header_30:'OS 8 & 9 11/10/2024',
    body_30:'Por razones de ciberseguridad, a partir del 1 de enero de 2025, mylife CamAPS FX solo será compatible con teléfonos compatibles con sensores que ejecuten sistemas operativos Android 10 y superiores. Los usuarios de sistemas operativos por debajo de OS 10, ya no podrán actualizar o reinstalar mylife CamAPS FX después de esta fecha',
	
	header_29:'Actualización de la aplicación 09/10/2024',
    body_29:'Estimado usuario de mylife CamAPS FX, Una nueva versión de la aplicación estará lista en las próximas 48 horas. Para elegir tu hora preferida para la actualización, desactiva la actualización automática para CamAPS FX en la Google Play Store. Si tienes alguna pregunta, por favor contacta al servicio al cliente de Ypsomed.',
	
	header_28:'OS 8 & 9 08/07/2024',
    body_28:'Por razones de ciberseguridad, a partir del 1 de enero de 2025, mylife CamAPS FX solo será compatible con teléfonos compatibles con sensores que ejecuten sistemas operativos Android 10 y superiores. Los usuarios de sistemas operativos por debajo de OS 10, ya no podrán actualizar o reinstalar mylife CamAPS FX después de esta fecha',
	
	header_27:'',
    body_27:'',
	
	header_26:'Actualización del manual de usuario 11/05/2024',
    body_26:'Existe una versión actualizada del manual de usuario.',
	
	header_25:'Actualización de la aplicación 05/05/2024',
    body_25:'Estimado usuario de mylife CamAPS FX, Una nueva versión de la aplicación estará lista en las próximas 24 horas. Para elegir tu hora preferida para la actualización, desactiva la actualización automática para CamAPS FX en la Google Play Store. Si tienes alguna pregunta, por favor contacta al servicio al cliente de Ypsomed.',
	
	header_24:'',
    body_24:'',
	
	header_23:'Actualización de la aplicación 24/04/2024',
    body_23:'Estimado usuario de mylife CamAPS FX, Una nueva versión de la aplicación estará lista en las próximas 24 horas. Para elegir tu hora preferida para la actualización, desactiva la actualización automática para CamAPS FX en la Google Play Store. Si tienes alguna pregunta, por favor contacta al servicio al cliente de Ypsomed.',
	
	header_22:'Actualización del manual de usuario 21/04/2024',
    body_22:'Existe una versión actualizada del manual de usuario.',
	
	header_21:'',
    body_21:'',
	
	header_20:'',
    body_20:'',
	
	header_19:'',
    body_19:'',

    header_18:'',
    body_18:'',

    header_17:'',
    body_17:'',

	header_16:'Actualización del manual de usuario 04/03/2024',
    body_16:'Existe una versión actualizada del manual de usuario.',
	
	header_15:'Actualización del manual de usuario 04/01/2024',
    body_15:'Existe una versión actualizada del manual de usuario.',
	
	header_14:' 22/11/2023',
    body_14:'Recordatorio: Para asegurar el uso ininterrumpido de la aplicación mylife CamAPS FX, por favor verifica en el sitio web del fabricante del sensor para verificar la compatibilidad antes de actualizar a Android OS 14. Nota: Las actualizaciones automáticas podrían estar activadas por defecto en la configuración de tu teléfono.de actualizar a Android OS 14.',
	
	header_13:'AVISO DE SEGURIDAD 14/11/2023',
    body_13:'AVISO DE SEGURIDAD SOBRE',
    body_13_link_text:'Por favor lea del aviso importante',
    body_13_end: '. Ir al menú de la aplicación -> Avisos de seguridad.',
    body_13_link:'https://s3.eu-west-1.amazonaws.com/camaps.safety.notices/Field+Safety+Notice+ET5604+Mylife+Users_ES_final+v2.pdf',

	header_12:'Actualización del manual de usuario 10/11/2023',
    body_12:'Existe una versión actualizada del manual de usuario.',
	
	header_11:'Android OS 14. 12/09/2023',
    body_11:'Para asegurar el uso ininterrumpido de la aplicación mylife CamAPS FX, por favor verifica en el sitio web del fabricante del sensor para verificar la compatibilidad antes de actualizar a Android OS 14. Nota: Las actualizaciones automáticas podrían estar activadas por defecto en la configuración de tu teléfono.Por favor, asegúrate de que estás ejecutando la aplicación mylife CamAPS FX versión 1.4(173) antes de actualizar a Android OS 14.',
	
	header_10:'AVISO DE SEGURIDAD 15/08/2023',
    body_10:'AVISO DE SEGURIDAD SOBRE Por favor lea del aviso importante. Ir al menú de la aplicación -> Avisos de seguridad.',
	
	header_9:'Actualización del manual de usuario 27/07/2023',
    body_9:'Existe una versión actualizada del manual de usuario.',
	
	header_8:'Compatibilidad de Glooko 21/07/2023',
    body_8:'Diasend se suspenderá el 28 de julio. Si desea seguir compartiendo sus datos con su equipo de diabetes, actualice su cuenta de Diasend a Glooko o cree una nueva cuenta de Glooko. Actualice a la última versión de mylife CamAPS FX, ingrese su nuevo nombre de usuario y contraseña de Glooko en el menú de compartir y reinicie su teléfono inteligente. Diasend seguirá disponible para ver los datos históricos durante el resto del año.',
	
	header_7:'Samsung A-Series 14/07/2023 ',
    body_7:'Los usuarios de los modelos Samsung A-Series (es decir, A53, A33) pueden haber experimentado pérdida de señal en su sensor CGM después de la última actualización de Samsung One UI 5.1. Samsung ha identificado el problema y se encuentra desarrollando una solución. Samsung planea lanzar una actualización de One UI el 24 de julio (según tu región), la cual resolverá el problema de pérdida de señal. Se recomienda instalar la actualización tan pronto como esté disponible. Si aún no has actualizado a One UI 5.1, te recomendamos esperar la próxima actualización Samsung One UI.',

    header_6:'Compatibilidad de Glooko 08/06/2023 ',
    body_6:'mylife CamAPS FX ahora es compatible con Glooko. Por favor, actualiza tu aplicación mylife CamAPS FX a la versión 1.4(172). Si estás compartiendo datos con una clínica, recibirás una notificación dentro de tu cuenta existente de diasend cuando tu clínica se actualice a Glooko. Una vez completado, ingresa tu nuevo nombre de usuario y contraseña de Glooko en el menú de compartir y reinicia tu smartphone. Por favor, actualiza a Glooko antes del 28 de julio de 2023. diasend estará disponible para ver datos históricos durante el resto del año.',

    header_5:'',
    body_5:'',

    header_4:'Actualización del manual de usuario 27/05/2023',
    body_4:'Existe una versión actualizada del manual de usuario.',

    header_3:'Alertas SMS (Sólo Ypsopump) 11/05/2023',
    body_3:'Con la próxima actualización 1.4(171) a mediados de mayo, podrás tener hasta 5 seguidores de SMS activos Y utilizar el modo Companion al mismo tiempo. Para utilizar el modo Companion, el teléfono del usuario debe tener una conexión a Internet para compartir datos. Los usuarios deben tener una tarjeta SIM para permitir el envío de SMS desde su teléfono. Si estás utilizando una de estas opciones, te recomendamos que compruebes que tu plan móvil incluya la cobertura de SMS y/o datos dependiendo de la opción que estés utilizando.',

    header_2:'Ciberseguridad (Sólo Ypsopump) 24/04/2023',
    body_2:'La seguridad de la aplicación mylife CamAPS FX es de suma importancia. A partir de mediados de mayo, habrá una actualización de ciberseguridad impuesta por Google Play Store. Tras esta actualización, es posible que un pequeño porcentaje de teléfonos no superen la comprobación de integridad de ciberseguridad. Puedes probar tu teléfono de antemano visitando el menú de ayuda y completando la comprobación. Si el teléfono no supera esta comprobación, prueba a desinstalar y volver a instalar la aplicación.',

    header_1:'',
    body_1:'',
};
