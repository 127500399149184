/**
 * Texts for Training page
 */
export const trainingPageText = {
    title: 'Utbildning - CamAPS FX',
    titleMeta: 'Vi tillhandahåller kostnadsfri webbaserad utbildning för CamAPS FX-användare.',
    descriptionMeta: 'Cambridge Diabetes Education Programme håller kostnadsfria webbaserade utbildningar för personer med diabetes typ 1, föräldrar och vårdnadshavare, skolpersonal och hälso- och sjukvårdspersonal.',
    keywords:
        'camps träning, camps fx träning, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'sv_se',

    trainingHeader: 'Utbildning med legitimerad utbildare',
    trainingHeader_2: 'För hälso- och sjukvårdspersonal',
    trainingHeader_3: 'Användarutbildning',
    trainingHeaderText: 'KOSTNADSFRI webbaserad utbildning i CamAPS FX tillhandahålls av Cambridge Diabetes Education Programme (CDEP).',
    websiteLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    websiteLinkHeading: 'CDEP-utbildning',
    getTrained: 'Gå utbildningen',

    trainingHeaderIat: "CamAPS FX-träning",
    trainingHeaderTextIat: "Genomför den grundläggande utbildningen i appen innan du använder CamAPS FX-appen.",
    trainingSubHeaderOneIat: "Kom igång",
    trainingSubTextOneIat: "Ladda ner CamAPS FX-appen. <br>Skapa ett konto för att påbörja träningen.",
    trainingSubHeaderTwoIat: "Behöver du hjälp med att installera appen?",
    trainingParaTwoStartIat: "Besök vår",
    trainingParaTwoEndIat: "sektion för vägledning.",
    websiteLinkHeadingSupportIat: "Support",
    trainingSubHeaderThreeIat: "Ytterligare resurser",
    animationsYpsoPumpText: "Animationer",
    animationsAndText: "och",
    animationsDanaText: "Animationer (Dana-pump)",
    animationsFurtherText: "Lär dig genom steg-för-steg visuella guider.",
    webinarsText: "Webbinarier",
    webinarsFurtherText: "Titta på expertledda sessioner om hur du använder appen.",
    websiteAnimationsYpsoPumpLink: "https://www.camdiabtraining.com/view/27/mylife-YpsoPump-users.htm",
    websiteAnimationsDanaLink: "https://www.camdiabtraining.com/view/25/Dana-insulin-pump-users.htm",
    websiteWebinarsLink: "https://www.camdiabtraining.com/view/20/Webinars.htm"

};
