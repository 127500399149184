/**
 * Texts for header
 */
export const headerText = {
    cookiesIntroText: 'Käytämme evästeitä ainoastaan verkkosivustomme käytön seurantaan. Emme tallenna henkilötietoja.',
    cookiesAccept: 'Hyväksy evästeet',
    cookiesDeny: 'Hylkää evästeet',
    tabFAQs: 'Usein kysyttyjä kysymyksiä',
    tabSupport: 'Tuki',
    tabHistory: 'Historia',
    tabReviews: 'Raportit',
    tabTraining: 'Koulutus',
    tabHcpTraining: '',
    // tabTraining: 'Käyttäjäkoulutus',
    // tabHcpTraining: 'Terveydenhuollon koulutus',
    // tabOrders: 'Tilaukset',
    tabOrders: '',
    tabNotifications: 'Ilmoitukset',
};
