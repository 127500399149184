/**
 * Texts for FAQs page
 */
export const faqPageText = {
    title: 'FAQs - CamAPS FX',
    titleMeta: 'Have a question about CamAPS FX?',
    descriptionMeta: 'Here are the answers to some of your frequently asked questions about compatible devices, ' +
        'regulatory approval, availability, and clinical research',
    keywords:
        'camaps faqs, camaps fx faqs, ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    localeMeta: 'en_gb',

    haveQuestion: 'Have a question about CamAPS FX?',
    hereAnswers: 'Here are the answers to some of your frequently asked questions.',
    whatIsCamAPS: 'What is CamAPS FX?',
    whatIsCamAPSAnswer: 'CamAPS FX is a sophisticated, easy-to-use mobile app to manage glucose levels in people ' +
        'with type 1 diabetes, aged one year and older, using an advanced adaptive hybrid closed-loop approach.',
    isApproved: 'Is CamAPS FX approved for use?',
    isApprovedAnswer: 'The app has been extensively tested in clinical trials and approved for ' +
        'use including in the UK, the EU, the USA, Canada, Australia, and New Zealand.',
    isAvailable: 'Is CamAPS FX available in my area?',
    isAvailableAnswer1: 'CamAPS FX is available in selected diabetes clinics. Please check with your clinic.',
    isAvailableAnswer2: '',
    isAvailableAnswer3: '',
    cost: 'How much does CamAPS FX cost?',
    costAnswer1: 'For costing details, please visit the',
    costAnswer2: 'section of the website.',
    compatibleCgm: 'What CGMs are compatible with CamAPS FX?',
    compatibleCgmAnswer: 'CamAPS FX is compatible with',
    compatibleCgmAnswer1: 'and the',
    compatibleCgmAnswer2: '. Please click the links to view compatible smartphones and operating systems.',
    whatPumps: 'What insulin pumps are compatible with CamAPS FX?',
    whatPumpsAnswer1: 'CamAPS FX available through Google Play is compatible with mylife YpsoPump insulin pump. ',
    whatPumpsAnswer2: '',
    whatPumpsAnswer3: 'CamAPS FX available through Amazon Appstore is compatible with ' +
        'DANA Diabecare RS and DANA-i insulin pumps.',
    whatPumpsAnswerHtml: 'https://advancedtherapeutics.org.uk/shop/dana-diabecare-insulin-pump/dana-i-insulin-pump',
    whatPhone: 'What smart phones are compatible with CamAPS FX?',

    whatPhoneAnswer: 'CamAPS FX runs on Android smartphones supported by your CGM manufacturer with OS 10 and above. It also runs with FreeStyle Libre 3 on iOS 17 or 18, currently in Sweden and rolling out globally soon.',
    whatPhoneAnswer1: 'Please click this',
    whatPhoneAnswer2: 'link',
    whatPhoneAnswer3: 'for a list of devices and operating systems compatible with',
    whatPhoneAnswerDexcom: 'the Dexcom G6 Sensor and the Dexcom G6 app.',
    whatPhoneAnswerLibre3: 'the FreeStyle Libre 3 Sensor and the FreeStyle Libre 3 app.' +
        '<p><p></p>' +
        'From the 1st January 2025, CamAPS FX will only be supported on sensor-compatible phones running Android operating systems 10 and above. Users of operating systems below OS 10, will no longer be able to update or reinstall CamAPS FX after this date.',
    whatPhoneAnswerDexcomHtml:'https://www.dexcom.com/compatibility',
    whatPhoneAnswerLibreHtml:'https://www.diabetescare.abbott/support/manuals.html',

    whereUpload: 'Where can I upload my data to from CamAPS FX? ',
    whereUploadAnswer: 'At present, data from CamAPS FX can be uploaded to mylife Cloud and Glooko.',
    whatLanguage: 'What languages are available within CamAPS FX?',
    whatLanguageAnswer: 'CamAPS FX is available in English, Czech, Danish, German, Spanish, French, Italian, Dutch, Polish, Finnish, and Swedish.',
    whereResults: 'Where can I find the results of your clinical trials?',
    whereResultsAnswer1: 'Publications related to the use of Cambridge control algorithm are available to download',
    whereResultsAnswer2: 'here.',
    whereResultsAnswer3: 'Key publications were published in',
    whereResultsAnswer4: 'and',
};
