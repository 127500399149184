/**
 * Texts for home page
 */
export const homePageText = {
    homePageTitle: 'CamAPS FX Insulinabgabesystem | Cambridge artificial pancreas',
    homePageTitleMeta: 'CamAPS FX Insulinabgabesystem mit geschlossenem Regelkreis für Typ-1-Diabetes',
    homePageDescriptionMeta: 'CamAPS FX Hybrid-Closed-Loop-App mit geschlossenem Regelkreis unter Verwendung von Insulinpumpe und CGM zum Schleifen bei Kindern, Erwachsenen und Schwangerschaften mit Diabetes',
    keywords:
        'camaps deutschland, camaps fx deutschland, cam aps deutschland, camaps fx closed loop deutschland, camaps fx app deutschland, ' +
        'cambridge closed loop deutschland, cam app, artificial pancreas deutschland, cam diabetes deutschland, closed loop deutschland' +
        // 'closed loop, closed loop system, loop deutsch, eine autoimmunerkrankung kommt selten allein, camaps fx kosten, glukose im blut, closed de, open loop closed loop, loop diabetes, was ist ein loop, hba1c zu niedrig, blutglukose, closed loop diabetes, dexcom app, insulin berechnen app, closed loop insulinpumpe, diabetes zimt therapie, glukose zu hoch, stopp diabetes, dexcom g6 closed loop, diabetes kohlenhydrate app, hybrid closed loop diabetes, loop dexcom g6, welche insulinpumpen gibt es, diabetes loop system, kohlenhydrate app android, kur diabetes typ 1, was heißt loop, mutter kind kur für diabetiker,  ' +
        '',
    localeMeta: 'de_de',

    introPara1: 'Die fortschrittliche, anpassungsfähige Hybrid-Closed-Loop-App, die automatisch die Insulinzufuhr Ihrer Insulinpumpe auf der Grundlage Ihrer Sensor-Glukosedaten regelt.',
    findOutMore: 'Mehr erfahren',
    sendMeInfo: 'Senden Sie mir Informationen',
    missionHeading: 'Unsere Mission',
    missionText: 'Menschen mit Typ-1-Diabetes und ihrer Familie helfen, ein besseres Leben zu leben.',
    adaptiveHeading: 'Anpassungsfähig und personalisiert',
    adaptiveText: 'Lernt und passt sich Ihrem Insulinbedarf an, während Sie gleichzeitig die Freiheit haben, Einstellungen zu verändern.',
    runsHeading: 'Läuft auf Ihrem Smartphone',
    runsText: 'Kompatibel mit Android OS.*',
    runsTextTrademark: '*Android ist ein Warenzeichen von Google LLC',
    allAgesHeading: 'Verfügbar für jedes Alter',
    allAgesText: 'Zugelassen ab dem Alter von 1 Jahr.',
    testedHeading: 'Umfangreich getestet',
    testedText_p1: 'Entwickelt an der Universität von Cambridge. Wird von vielen als der "beste Algorithmus dk draußen" angesehen.',
    testedText_p2: 'Randomisierte klinische Studien zeigten einen signifikanten Anstieg an Zeit im Zielbereich, eine Reduktion von HbA1c, eine Reduktion der Zeit mit Hypoglykämie und Hyperglykämie sowie verbessertes Wohlbefinden.',
    testedText_p3: 'Review der Leistung, Funktionalität und der App-Informationen der CamAPS FX App wird spätestens alle sechs Monate durchgeführt.',
    whatIsFXheading: 'Was ist CamAPS FX?',
    whatIsFXtext: 'CamAPS FX ist eine mobile App zum Management der Glukosewerte mit einem fortschrittlichen, anpassungsfähigen hybriden Closed-Loop-Ansatz bei Menschen mit Typ-1-Diabetes, die 1 Jahr und älter sind.',

    pressReleaseTitle: 'Pressemeldungen',
    dateYpsoPressRelease: '22. März 2022',
    headingYpsoPressRelease: 'Partnerschaft zwischen CamDiab und Ypsomed',
    textYpsoPressRelease: 'Ypsomed kooperiert mit CamDiab Ltd, um die adaptive automatisierte Insulinabgabe auf Smartphone-Basis voranzutreiben.',

    dateAdcPressRelease: '27. April 2022',
    headingAdcPressRelease: 'Partnerschaft zwischen Abbott Diabetes Care, CamDiab und Ypsomed',
    textAdcPressRelease: 'Abbott, CamDiab und Ypsomed kündigen neue Partnerschaft zur automatisierten Insulinabgabe für Menschen mit Diabetes an.',

    dateLibrePressRelease: '25. November 2022',
    headingLibrePressRelease: 'FreeStyle Libre 3 und mylife CamAPS FX',
    textLibrePressRelease: 'Ypsomed und CamDiab lancieren mit dem FreeStyle Libre&nbsp;3 von Abbott das erste System zur automatischen Insulindosierung.',

    dateFDAPressRelease: '24. Mai 2024',
    headingFDAPressRelease: 'Die FDA genehmigt CamAPS FX',
    textFDAPressRelease: 'CamDiab sichert sich die FDA-Zulassung für die fortschrittliche adaptive Hybrid-Closed-Loop-App CamAPS FX.',

    dateIOSPressRelease: '18. März 2025',
    headingIOSPressRelease: 'Ypsomed und CamDiab starten mylife Loop auf iOS',
    textIOSPressRelease: 'Ypsomed bringt gemeinsam mit CamDiab die iOS-Version der mylife CamAPS FX-App auf den Markt, die Teil des automatisierten Insulinabgabesystems mylife Loop ist.'

};
