import React, {Component} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {trainingPageText as trainingPageText_en} from "../libs/en/texts-training-page-lib";
import {trainingPageText as trainingPageText_en_ca} from "../libs/en-ca/texts-training-page-lib";
import {trainingPageText as trainingPageText_de} from "../libs/de/texts-training-page-lib";
import {trainingPageText as trainingPageText_fr} from "../libs/fr/texts-training-page-lib";
import {trainingPageText as trainingPageText_fr_ca} from "../libs/fr-ca/texts-training-page-lib";
import {trainingPageText as trainingPageText_nl} from "../libs/nl/texts-training-page-lib";
import {trainingPageText as trainingPageText_it} from "../libs/it/texts-training-page-lib";
import {trainingPageText as trainingPageText_cz} from "../libs/cz/texts-training-page-lib";
import {trainingPageText as trainingPageText_fi} from "../libs/fi/texts-training-page-lib";
import {trainingPageText as trainingPageText_pl} from "../libs/pl/texts-training-page-lib";
import {trainingPageText as trainingPageText_se} from "../libs/se/texts-training-page-lib";
import {trainingPageText as trainingPageText_no} from "../libs/no/texts-training-page-lib";
import {trainingPageText as trainingPageText_dk} from "../libs/dk/texts-training-page-lib";
import {trainingPageText as trainingPageText_es} from "../libs/es/texts-training-page-lib";
import parse from "html-react-parser";
import {Helmet} from 'react-helmet';
import {Link} from "react-router-dom";

// Tab for HCP training
export class TrainingHCP extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: this.props.language,
            trainingPageText: trainingPageText_en,
            prefix: '',
            showInAppTraining: false
        };
        if (this.state.language === "DE") {
            this.state.trainingPageText = trainingPageText_de;
            this.state.prefix = '/de';
            this.state.showInAppTraining = false;
        }
        if (this.state.language === "EN-CA") {
            this.state.trainingPageText = trainingPageText_en_ca;
            this.state.prefix = '/en-ca';
            this.state.showInAppTraining = false;
        }
        if (this.state.language === "FR-FR") {
            this.state.trainingPageText = trainingPageText_fr;
            this.state.prefix = '/fr';
            this.state.showInAppTraining = false;
        }
        if (this.state.language === "FR") {
            this.state.trainingPageText = trainingPageText_fr;
            this.state.prefix = '/fr-ch';
            this.state.showInAppTraining = false;
        }
        if (this.state.language === "FR-CA") {
            this.state.trainingPageText = trainingPageText_fr_ca;
            this.state.prefix = '/fr-ca';
            this.state.showInAppTraining = false;
        }
        if (this.state.language === "NL") {
            this.state.trainingPageText = trainingPageText_nl;
            this.state.prefix = '/nl';
            this.state.showInAppTraining = false;
        }
        if (this.state.language === "IT") {
            this.state.trainingPageText = trainingPageText_it;
            this.state.prefix = '/it';
            this.state.showInAppTraining = false;
        }
        if (this.state.language === "CZ") {
            this.state.trainingPageText = trainingPageText_cz;
            this.state.prefix = '/cz';
            this.state.showInAppTraining = false;
        }
        if (this.state.language === "FI") {
            this.state.trainingPageText = trainingPageText_fi;
            this.state.prefix = '/fi';
            this.state.showInAppTraining = false;
        }
        if (this.state.language === "PL") {
            this.state.trainingPageText = trainingPageText_pl;
            this.state.prefix = '/pl';
            this.state.showInAppTraining = false;
        }
        if (this.state.language === "SE") {
            this.state.trainingPageText = trainingPageText_se;
            this.state.prefix = '/se';
            this.state.showInAppTraining = false;
        }
        if (this.state.language === "NO") {
            this.state.trainingPageText = trainingPageText_no;
            this.state.prefix = '/nb';
            this.state.showInAppTraining = false;
        }
        if (this.state.language === "DK") {
            this.state.trainingPageText = trainingPageText_dk;
            this.state.prefix = '/dk';
            this.state.showInAppTraining = false;
        }
        if (this.state.language === "ES") {
            this.state.trainingPageText = trainingPageText_es;
            this.state.prefix = '/es';
            this.state.showInAppTraining = false;
        }
    }

    setLanguageVars() {
        if (this.state.language !== this.props.language) {
            if (this.props.language === "EN") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_en,
                    prefix: '',
                    showInAppTraining: false,
                });
            }
            if (this.props.language === "DE") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_de,
                    prefix: '/de',
                    showInAppTraining: false,
                });
            }
            if (this.props.language === "EN-CA") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_en_ca,
                    prefix: '/en-ca',
                    showInAppTraining: false,
                });
            }
            if (this.props.language === "FR") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_fr,
                    prefix: '/fr-ch',
                    showInAppTraining: false,
                });
            }
            if (this.props.language === "FR-CA") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_fr_ca,
                    prefix: '/fr-ca',
                    showInAppTraining: false,
                });
            }
            if (this.props.language === "FR-FR") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_fr,
                    prefix: '/fr',
                    showInAppTraining: false,
                });
            }
            if (this.props.language === "NL") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_nl,
                    prefix: '/nl',
                    showInAppTraining: false,
                });
            }
            if (this.props.language === "IT") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_it,
                    prefix: '/it',
                    showInAppTraining: false,
                });
            }
            if (this.props.language === "CZ") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_cz,
                    prefix: '/cz',
                    showInAppTraining: false,
                });
            }
            if (this.props.language === "FI") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_fi,
                    prefix: '/fi',
                    showInAppTraining: false,
                });
            }
            if (this.props.language === "PL") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_pl,
                    prefix: '/pl',
                    showInAppTraining: false,
                });
            }
            if (this.props.language === "SE") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_se,
                    prefix: '/se',
                    showInAppTraining: false,
                });
            }
            if (this.props.language === "NO") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_no,
                    prefix: '/nb',
                    showInAppTraining: false,
                });
            }
            if (this.props.language === "DK") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_dk,
                    prefix: '/dk',
                    showInAppTraining: false,
                });
            }
            if (this.props.language === "ES") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_es,
                    prefix: '/es',
                    showInAppTraining: false,
                });
            }
        }
    }

    componentDidUpdate() {
        this.setLanguageVars();
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{parse(this.state.trainingPageText.title)}</title>
                    <meta name="title" content={parse(this.state.trainingPageText.titleMeta)}/>
                    <meta name="description"
                          content={parse(this.state.trainingPageText.descriptionMeta)}/>
                    <meta property="og:locale" content={this.state.trainingPageText.localeMeta}/>
                    <meta name="keywords" content={this.state.trainingPageText.keywords}/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner hcp-page">
                    <div className="container">
                        {(!this.state.showInAppTraining) &&
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-12 hcp-col-main content-wrap">
                                    <h1>{parse(this.state.trainingPageText.trainingHeader)}</h1>
                                    <h2>{parse(this.state.trainingPageText.trainingHeader_2)}</h2>
                                    <div className="hcp-col">
                                        <p>{parse(this.state.trainingPageText.trainingHeaderText)}</p>
                                    </div>
                                    <a href={parse(this.state.trainingPageText.websiteLink)}
                                       title={parse(this.state.trainingPageText.websiteLinkHeading)}
                                       className="btn">
                                        {parse(this.state.trainingPageText.getTrained)}</a>
                                </div>
                            </div>
                        }
                        {(this.state.showInAppTraining) &&
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-12 hcp-col-main content-wrap">
                                    <h1>{parse(this.state.trainingPageText.trainingHeaderIat)}</h1>
                                    <h2>{parse(this.state.trainingPageText.trainingHeader_2)}</h2>
                                    <div className="hcp-col">
                                        <p>{parse(this.state.trainingPageText.trainingHeaderTextIat)}</p>
                                    </div>
                                    <div className="hcp-col-tra">
                                        <p>{parse(this.state.trainingPageText.trainingSubHeaderOneIat)}</p>
                                    </div>
                                    <div className="hcp-col-small">
                                        {parse(this.state.trainingPageText.trainingSubTextOneIat)}
                                    </div>
                                    {/*Getting started */}
                                    <div className="hcp-col-tra">
                                        <p>{parse(this.state.trainingPageText.trainingSubHeaderTwoIat)}</p>
                                    </div>
                                    <div className="hcp-col-small">
                                        {parse(this.state.trainingPageText.trainingParaTwoStartIat)}
                                        &nbsp;
                                        <b><u>
                                            <Link to={this.state.prefix + "/support"}>
                                                {parse(this.state.trainingPageText.websiteLinkHeadingSupportIat)}</Link>
                                        </u></b>
                                        &nbsp;
                                        {parse(this.state.trainingPageText.trainingParaTwoEndIat)}
                                    </div>
                                    {/*<a href={parse(this.state.trainingPageText.websiteLink)}*/}
                                    {/*   title={parse(this.state.trainingPageText.websiteLinkHeading)}*/}
                                    {/*   className="btn">*/}
                                    {/*    {parse(this.state.trainingPageText.getTrained)}</a>*/}

                                    {/*Additional resources*/}
                                    <div className="hcp-col-tra">
                                        <p>{parse(this.state.trainingPageText.trainingSubHeaderThreeIat)}</p>
                                    </div>
                                    <div className="hcp-col-small">
                                        <b>
                                            <u>
                                                <a href={parse(this.state.trainingPageText.websiteAnimationsYpsoPumpLink)}
                                                   title={parse(this.state.trainingPageText.animationsYpsoPumpText)}
                                                   target="_blank"
                                                   rel="noopener noreferrer">
                                                    {parse(this.state.trainingPageText.animationsYpsoPumpText)}</a>
                                            </u>
                                        </b>

                                        {(this.state.language === "EN") &&
                                            <b>
                                                &nbsp;
                                            </b>
                                        }
                                        {(this.state.language === "EN") &&
                                            parse(this.state.trainingPageText.animationsAndText)}
                                        {(this.state.language === "EN") &&
                                            <b>
                                                &nbsp;
                                                <u>
                                                    <a href={parse(this.state.trainingPageText.websiteAnimationsDanaLink)}
                                                       title={parse(this.state.trainingPageText.animationsDanaText)}
                                                       target="_blank"
                                                       rel="noopener noreferrer">
                                                        {parse(this.state.trainingPageText.animationsDanaText)}</a>
                                                </u>
                                            </b>
                                        }
                                        :&nbsp;
                                        {parse(this.state.trainingPageText.animationsFurtherText)}
                                    {/*</div>*/}
                                    {/*<div className="hcp-col-small">*/}
                                        <br></br>
                                        <b>
                                            <u>
                                            <a href={parse(this.state.trainingPageText.websiteWebinarsLink)}
                                               title={parse(this.state.trainingPageText.webinarsText)}
                                               target="_blank"
                                               rel="noopener noreferrer">
                                                {parse(this.state.trainingPageText.webinarsText)}</a>
                                            </u>
                                        </b>
                                        :&nbsp;
                                        {parse(this.state.trainingPageText.webinarsFurtherText)}
                                    </div>
                                    {/*<a href={parse(this.state.trainingPageText.websiteLink)}*/}
                                    {/*   title={parse(this.state.trainingPageText.websiteLinkHeading)}*/}
                                    {/*   className="btn">*/}
                                    {/*    {parse(this.state.trainingPageText.getTrained)}</a>*/}
                                </div>
                            </div>
                        }
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

