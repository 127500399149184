/**
 * Texts for Notifications page
 */
export const notificationsPageText = {
    title: 'Ostatnie powiadomienia w aplikacji',
    titleMeta: 'Wyświetlanie ostatnich powiadomień w aplikacji CamAPS FX',
    descriptionMeta: 'Oto tekst ostatnich powiadomień w aplikacji dotyczących aktualizacji aplikacji, uwag dotyczących bezpieczeństwa i aktualizacji instrukcji obsługi',
    keywords: 'powiadomienia' ,
    localeMeta: 'pl_pl',

    heading: 'Ostatnie powiadomienia w aplikacji',
    subheading: '',

       header_39:'',
    body_39:'',

       header_38:'',
    body_38:'',

       header_37:'Zakończenie obsługi starej wersji aplikacji (tylko Ypsopump)',
    body_37:'Obecna wersja przestanie działać 7 lutego 2025 roku. Zaktualizuj swoją aplikację do najnowszej wersji dostępnej w Google Play.',

       header_36:'Nowa wersja aplikacji 06/01/2025',
    body_36:'Szanowny użytkowniku mylife CamAPS FX, Nowa wersja aplikacji będzie gotowa w ciągu najbliższych 48 godzin. Aby wybrać preferowany czas aktualizacji, wyłącz automatyczną aktualizację dla CamAPS FX w Google Play Store. Jeśli masz jakiekolwiek pytania, skontaktuj się z obsługą klienta Ypsomed.',
	
	header_35:'OS 8 & 9 11/10/2024',
    body_35:'Ze względów cyberbezpieczeństwa, od 1 stycznia 2025 roku, mylife CamAPS FX będzie obsługiwany tylko na telefonach kompatybilnych z czujnikami z systemem Android 10 i nowszym. Użytkownicy systemów operacyjnych poniżej OS 10 nie będą mogli aktualizować ani ponownie instalować mylife CamAPS FX po tej dacie. Przypomnienie, system nie jest jeszcze kompatybilny z OS15',
	
	header_34:'',
    body_34:'',
	
	header_33:'Ogłoszenie o planowanej konserwacji 05/12/2024',
    body_33:'Z powodu zaplanowanej konserwacji chmury usługi CamAPS FX, funkcjonalność Companion będzie niedostępna 7 grudnia od godziny 10:00 GMT przez około trzy godziny. Przepraszamy za wszelkie niedogodności, które mogą wyniknąć z tej sytuacji.',
	
	header_32:'Aktualizacja instrukcji obsługi 11/05/2024',
    body_32:'Dostępna jest zaktualizowana wersja instrukcji obsługi.',
	
	header_31:'Nowa wersja aplikacji 17/11/2024',
    body_31:'Szanowny użytkowniku mylife CamAPS FX, Nowa wersja aplikacji będzie gotowa w ciągu najbliższych 48 godzin. Aby wybrać preferowany czas aktualizacji, wyłącz automatyczną aktualizację dla CamAPS FX w Google Play Store. Jeśli masz jakiekolwiek pytania, skontaktuj się z obsługą klienta Ypsomed.',
	
	header_30:'OS 8 & 9 11/10/2024',
    body_30:'Ze względów cyberbezpieczeństwa, od 1 stycznia 2025 roku, mylife CamAPS FX będzie obsługiwany tylko na telefonach kompatybilnych z czujnikami z systemem Android 10 i nowszym. Użytkownicy systemów operacyjnych poniżej OS 10 nie będą mogli aktualizować ani ponownie instalować mylife CamAPS FX po tej dacie.',
	
	header_29:'Nowa wersja aplikacji 09/10/2024',
    body_29:'Szanowny użytkowniku mylife CamAPS FX, Nowa wersja aplikacji będzie gotowa w ciągu najbliższych 48 godzin. Aby wybrać preferowany czas aktualizacji, wyłącz automatyczną aktualizację dla CamAPS FX w Google Play Store. Jeśli masz jakiekolwiek pytania, skontaktuj się z obsługą klienta Ypsomed.',
	
	header_28:'OS 8 & 9 08/07/2024',
    body_28:'Ze względów cyberbezpieczeństwa, od 1 stycznia 2025 roku, mylife CamAPS FX będzie obsługiwany tylko na telefonach kompatybilnych z czujnikami z systemem Android 10 i nowszym. Użytkownicy systemów operacyjnych poniżej OS 10 nie będą mogli aktualizować ani ponownie instalować mylife CamAPS FX po tej dacie.',

	header_27:'',
    body_27:'',
	
	header_26:'Aktualizacja instrukcji obsługi 11/05/2024',
    body_26:'Dostępna jest zaktualizowana wersja instrukcji obsługi.',
	
	header_25:'Aktualizacja instrukcji obsługi 05/05/2024',
    body_25:'Szanowny użytkowniku mylife CamAPS FX, Nowa wersja aplikacji będzie gotowa w ciągu najbliższych 24 godzin. Aby wybrać preferowany czas aktualizacji, wyłącz automatyczną aktualizację dla CamAPS FX w Google Play Store. Jeśli masz jakiekolwiek pytania, skontaktuj się z obsługą klienta Ypsomed.',
	
	header_24:'',
    body_24:'',
	
	header_23:'Nowa wersja aplikacji 24/04/2024',
    body_23:'Szanowny użytkowniku mylife CamAPS FX, Nowa wersja aplikacji będzie gotowa w ciągu najbliższych 24 godzin. Aby wybrać preferowany czas aktualizacji, wyłącz automatyczną aktualizację dla CamAPS FX w Google Play Store. Jeśli masz jakiekolwiek pytania, skontaktuj się z obsługą klienta Ypsomed.',
	
	header_22:'Aktualizacja instrukcji obsługi 21/04/2024',
    body_22:'Dostępna jest zaktualizowana wersja instrukcji obsługi.',
	
	header_21:'',
    body_21:'',
	
	header_20:'',
    body_20:'',
	
	header_19:'',
    body_19:'',

    header_18:'',
    body_18:'',

    header_17:'',
    body_17:'',

	header_16:'Aktualizacja instrukcji obsługi 04/03/2024',
    body_16:'Dostępna jest zaktualizowana wersja instrukcji obsługi.',
	
	header_15:'Aktualizacja instrukcji obsługi 04/01/2024',
    body_15:'Dostępna jest zaktualizowana wersja instrukcji obsługi.',
	
	header_14:'Android OS 14. 22/11/2023',
    body_14:'Przypomnienie: Aby zapewnić nieprzerwane korzystanie z aplikacji mylife CamAPS FX, proszę sprawdzić stronę internetową producenta czujnika stężenia glukozy w celu zweryfikowania kompatybilności przed aktualizacją do systemu Android OS 14. Uwaga: Automatyczne aktualizacje mogą być domyślnie włączone w ustawieniach Twojego telefonu.',
	
	header_13:'KOMUNIKAT DOTYCZĄCY BEZPIECZEŃSTWA STOSOWANIA PRODUKTU 14/11/2023',
    body_13:'KOMUNIKAT DOTYCZĄCY BEZPIECZEŃSTWA STOSOWANIA PRODUKTU ',
    body_13_link_text:'Proszę przeczytać tę ważną informację ostrzegawczą',
    body_13_end: '. Przejdź do menu aplikacji -> Powiadomienia o bezpieczeństwie i otwórz odnośnik do powiadomienia o bezpieczeństwie.',
    body_13_link:'https://s3.eu-west-1.amazonaws.com/camaps.safety.notices/Field+Safety+Notice+ET5604+Mylife+Users+final+-+PL.pdf',

	header_12:'Aktualizacja instrukcji obsługi 10/11/2023',
    body_12:'Dostępna jest zaktualizowana wersja instrukcji obsługi.',
	
	header_11:'Android OS 14. 12/09/2023',
    body_11:'Aby zapewnić nieprzerwane korzystanie z aplikacji mylife CamAPS FX, proszę sprawdzić stronę internetową producenta czujnika stężenia glukozy w celu zweryfikowania kompatybilności przed aktualizacją do systemu Android OS 14. Uwaga: Automatyczne aktualizacje mogą być domyślnie włączone w ustawieniach Twojego telefonu.Upewnij się, że korzystasz z aplikacji mylife CamAPS FX w wersji 1.4(173) przed aktualizacją do systemu Android OS 14.',
	
	header_10:'KOMUNIKAT DOTYCZĄCY BEZPIECZEŃSTWA STOSOWANIA PRODUKTU 15/08/2023',
    body_10:'KOMUNIKAT DOTYCZĄCY BEZPIECZEŃSTWA STOSOWANIA PRODUKTU Proszę przeczytać tę ważną informację ostrzegawczą. Przejdź do menu aplikacji -> Powiadomienia o bezpieczeństwie i otwórz odnośnik do powiadomienia o bezpieczeństwie.',
	
	header_9:'Aktualizacja instrukcji obsługi 27/07/2023',
    body_9:'Dostępna jest zaktualizowana wersja instrukcji obsługi.',
	
	header_8:'',
    body_8:'',
	
	header_7:'Samsung A-Series 14/07/2023',
    body_7:'Użytkownicy modeli Samsung A-Series (np. A53, A33) mogli doświadczyć utraty sygnału ze swojego czujnika CGM po najnowszej aktualizacji nakładki Samsung One UI 5.1. Samsung zidentyfikował ten problem i rozwiązanie jest obecnie w trakcie opracowywania. Samsung planuje wydać aktualizację One UI w dniu 24 lipca (w zależności od Twojego regionu), która rozwiąże problem utraty sygnału. Zaleca się zainstalowanie aktualizacji, gdy stanie się dostępna. Jeśli nie zaktualizowałeś jeszcze do wersji One UI 5.1, zalecamy zaczekać na kolejną aktualizację Samsung One UI.',

    header_6:'',
    body_6:'',

    header_5:'',
    body_5:'',

    header_4:'Aktualizacja instrukcji obsługi 27/05/2023',
    body_4:'Dostępna jest zaktualizowana wersja instrukcji obsługi.',

    header_3:'Alerty SMS (Tylko Ypsopump) 11/05/2023',
    body_3:'Wiadomość:Wraz z nadchodzącą w połowie maja aktualizacją 1.4(171) będzie można aktywować do 5 obserwatorów SMS i jednocześnie korzystać z trybu Companion. Korzystanie z trybu Companion wymaga, aby telefon użytkownika miał połączenie internetowe dla udostępniania danych. Użytkownicy muszą mieć kartę SIM do wysyłania SMS-ów z ich telefonu. Korzystającym z jednej z tych opcji, zalecamy sprawdzenie czy plan taryfowy pokrywa koszty SMS-ów i/lub transmisji danych w zależności od używanej opcji.',

    header_2:'Aktualizacja cyberbezpieczeństwa (Tylko Ypsopump) 24/04/2023',
    body_2:'Bezpieczeństwo aplikacji mylife CamAPS FX jest niezwykle ważne. Od połowy maja będzie miała miejsce aktualizacja cyberbezpieczeństwa wymuszona przez Google Play Store. Po tej aktualizacji niewielka część telefonów może nie przejść kontroli integralności cyberbezpieczeństwa. Możesz przetestować swój telefon z wyprzedzeniem, odwiedzając menu pomocy i wypełniając kontrolę. Jeśli telefon nie przejdzie tego testu, spróbuj odinstalować i ponownie zainstalować aplikację.',

    header_1:'',
    body_1:'',
};
