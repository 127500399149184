/**
 * Texts for header
 */
export const headerText = {
    cookiesIntroText: 'Vi bruger cookies, men kun til at spore besøg på vores hjemmeside, vi gemmer ikke nogen personlige oplysninger.',
    cookiesAccept: 'Acceptér cookies',
    cookiesDeny: 'Afvis cookies',
    tabFAQs: 'Ofte stillede spørgsmål (FAQ)',
    tabSupport: 'Support',
    tabHistory: 'Historie',
    tabReviews: 'Anmeldelser',
    tabTraining: 'Oplæring',
    tabHcpTraining: '',
    // tabTraining: 'Brugertræning',
    // tabHcpTraining: 'Sundhedstræning',
    // tabOrders: 'Ordrer',
    tabOrders: '',
    tabNotifications: 'Meddelelser',
};
