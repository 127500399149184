/**
 * Texts for header
 */
export const headerText = {
    cookiesIntroText: 'Vi använder cookies enbart för att spåra besöken på vår webbplats. Vi lagrar inga personuppgifter.',
    cookiesAccept: 'Godkänn cookies',
    cookiesDeny: 'Avböj cookies',
    tabFAQs: 'FAQ',
    tabSupport: 'Support',
    tabHistory: 'Historik',
    tabReviews: 'Recensioner',
    tabTraining: 'Användarutbildning',
    tabHcpTraining: 'Vårdutbildning',
    // tabOrders: 'Beställningar',
    tabOrders: '',
    tabNotifications: 'Anmälningar',
};
