/**
 * Texts for header
 */
export const headerText = {
    cookiesIntroText: 'Nous utilisons des cookies qui ne servent qu’à suivre vos visites sur notre site. Nous ne stockons aucune donnée personnelle.',
    cookiesAccept: 'Accepter les cookies',
    cookiesDeny: 'Refuser les cookies',
    tabFAQs: 'Questions',
    tabSupport: 'Assistance',
    tabHistory: 'Notre histoire',
    tabReviews: 'Témoignages',
    tabTraining: 'Formation',
    tabHcpTraining: '',
    // tabTraining: 'Formation utilisateurs',
    // tabHcpTraining: 'Formation pro-santé',
    // tabOrders: 'Commander',
    tabOrders: '',
    tabNotifications: 'Notifications',

};
