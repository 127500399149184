/**
 * Texts for FAQs page
 */
export const faqPageText = {
    title: 'Preguntas frecuentes - CamAPS FX',
    titleMeta: '¿Tiene preguntas sobre CamAPS FX?',
    descriptionMeta: 'He aquí las respuestas a algunas de sus preguntas más frecuentes sobre los dispositivos compatibles, la aprobación reglamentaria, la disponibilidad y la investigación clínica',
    keywords:
        'preguntas frecuentes de camaps, preguntas frecuentes de camaps fx, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'es_es',

    haveQuestion: '¿Tiene preguntas sobre CamAPS FX?',
    hereAnswers: 'He aquí las respuestas a algunas de sus preguntas más frecuentes.',
    whatIsCamAPS: '¿Qué es CamAPS FX?',
    whatIsCamAPSAnswer: 'CamAPS FX es una aplicación móvil sofisticada y fácil de usar para controlar los niveles de glucosa en personas con diabetes de tipo 1, a partir de 1 año de edad. Está basada en un avanzado enfoque adaptativo de Loop híbrido cerrado.',
    isApproved: '¿Está aprobado el uso de CamAPS FX?',
    isApprovedAnswer: 'La aplicación se ha probado exhaustivamente en ensayos clínicos y su uso se ha aprobado, también en el Reino Unido y la UE.',
    isAvailable: '¿Está CamAPS FX disponible en mi zona?',
    isAvailableAnswer1: 'CamAPS FX está disponible en determinados consultorios de diabetes o para quienes se autofinancien y reciban formación en línea. Acuda a su consultorio o diríjase a',
    isAvailableAnswer2: 'Pedidos',
    isAvailableAnswer3: 'para encargar la aplicación.',
    cost: '¿Cuánto cuesta CamAPS FX?',
    costAnswer1: 'Para consultar los precios, visite el',
    costAnswer2: 'apartado correspondiente del sitio web.',
    compatibleCgm: '¿Qué MCG son compatibles con CamAPS FX?',
    compatibleCgmAnswer: 'CamAPS FX es compatible con el',
    compatibleCgmAnswer1: 'y el',
    compatibleCgmAnswer2: '. Por favor, haga clic en los enlaces para ver los smartphones y sistemas operativos compatibles.',
    whatPumps: '¿Qué bombas de insulina son compatibles con CamAPS FX?',
    whatPumpsAnswer1: 'CamAPS FX es compatible con',
    whatPumpsAnswer2: 'las bombas de insulina mylife YpsoPump, Dana Diabecare RS y DANA-i.',
    whatPumpsAnswer3: '',
    whatPumpsAnswerHtml: 'https://advancedtherapeutics.org.uk/shop/dana-diabecare-insulin-pump/dana-rs-insulin-pump-2018',
    whatPhone: '¿Qué smartphones son compatibles con CamAPS FX?',
    whatPhoneAnswer: 'CamAPS FX se ejecuta en smartphones Android compatibles con su fabricante de MCG con OS 10 y superior. También se ejecuta con FreeStyle Libre 3 en iOS 17 o 18, actualmente en Suecia y próximamente a nivel mundial.',

    whatPhoneAnswer1: 'Haga',
    whatPhoneAnswer2: 'clic',
    whatPhoneAnswer3: 'en este enlace para obtener una lista de dispositivos y sistemas operativos compatibles con',
    whatPhoneAnswerDexcom: 'el sensor Dexcom G6 y la aplicación Dexcom G6.',
    whatPhoneAnswerLibre3: 'el sensor FreeStyle Libre 3 y la aplicación FreeStyle Libre 3.' +
        '<p><p></p>' +
        'A partir del 1 de enero de 2025, CamAPS FX solo será compatible con teléfonos compatibles con sensores que ejecuten sistemas operativos Android 10 y superiores. Los usuarios de sistemas operativos por debajo de OS 10, ya no podrán actualizar o reinstalar CamAPS FX después de esta fecha.',
    whatPhoneAnswerDexcomHtml:'https://www.dexcom.com/compatibility',
    whatPhoneAnswerLibreHtml:'https://www.diabetescare.abbott/support/manuals.html',

    whereUpload: '¿Dónde puedo cargar mis datos desde CamAPS FX?',
    whereUploadAnswer: 'Actualmente, los datos de CamAPS FX se pueden cargar en mylife Cloud y Glooko.',
    whatLanguage: '¿Qué idiomas hay disponibles en CamAPS FX?',
    whatLanguageAnswer: 'CamAPS FX está disponible en inglés, checo, danés, alemán, español, francés, italiano, holandés, polaco, finlandés y sueco.',
    whereResults: '¿Dónde están los resultados de sus ensayos clínicos?',
    whereResultsAnswer1: 'Las publicaciones relacionadas con el uso del algoritmo de control Cambridge se pueden descargar',
    whereResultsAnswer2: 'aquí.',
    whereResultsAnswer3: 'Las principales publicaciones se publicaron en',
    whereResultsAnswer4: 'y',
};
