/**
 * Texts for footer
 */
export const footerText = {
    txtFAQs: 'FAQs',
    txtSupport: 'Support',
    txtHistory: 'Geschichte',
    txtTrainingHtml: 'https://de.camdiabtraining.com',
    txtTraining: 'Online Training',
    txtClinic: 'CamAPS FX Klinik',
    terms: 'Allgemeine Geschäftsbedingungen',
    privacy: 'Datenschutzbestimmungen',
    txtFooterBottom: 'CamAPS ist eine eingetragene Marke von CamDiab Ltd. Andere Warenzeichen sind Eigentum der jeweiligen Inhaber. CamDiab Markenzeichen, Markennamen oder Firmenaufmachung auf dieser Website dürfen nur mit vorheriger schriftlicher Zustimmung von CamDiab verwendet werden, außer sie werden zur Kennzeichnung des Produktes oder des Service des Unternehmens genutzt. Die Produktbilder dienen ausschließlich der Illustration.',
    rightsReserved: 'CamDiab Ltd. Alle Rechte vorbehalten.',

    statusPageFooterText: 'Statusseite',
    statusPageFooterLink: 'https://camdiabltd.statuspage.io/',

    sscpPageFooterText: 'CamAPS FX Sicherheitsinformationen (SSCP)',
    sscpPageFooterHtml: 'https://s3.eu-west-1.amazonaws.com/camdiab.user.manual/APCam-FX(FX)-D47_SSP_DE.pdf',
};

