/**
 * Texts for footer
 */
export const footerText = {
    txtFAQs: 'FAQ',
    txtSupport: 'Support',
    txtHistory: 'Historik',
    txtTrainingHtml: 'https://www.camdiabtraining.com/account-sign-in.html',
    txtTraining: 'Webbaserad utbildning',
    txtClinic: 'CamAPS FX-klinik',
    terms: 'Villkor',
    privacy: 'Dataskyddspolicy',
    txtFooterBottom: 'CamAPS är ett registrerat varumärke som tillhör CamDiab Ltd. Andra varumärken tillhör respektive ägare. Varken CamDiabs varumärke, handelsnamn eller utstyrsel på denna webbplats får användas på något sätt utan föregående skriftligt medgivande från CamDiab, annat än för att identifiera företagets produkt eller tjänster. Produktbilderna är enbart avsedda som illustration.',
    rightsReserved: 'CamDiab Ltd. Med ensamrätt.',

    statusPageFooterText: 'Statussida',
    statusPageFooterLink: 'https://camdiabltd.statuspage.io/',

    sscpPageFooterText: 'CamAPS FX Säkerhetsinformation (SSCP)',
    sscpPageFooterHtml: 'https://s3.eu-west-1.amazonaws.com/camdiab.user.manual/APCam-FX(FX)-D47_SSP_SE.pdf',
};
