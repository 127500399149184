/**
 * Texts for FAQs page
 */
export const faqPageText = {
    title: 'Veelgestelde vragen - CamAPS FX',
    titleMeta: 'Heeft u een vraag over CamAPS FX?',
    descriptionMeta: 'Hier vindt u de antwoorden op een aantal veelgestelde vragen over compatibele apparaten, wettelijke goedkeuring, beschikbaarheid en klinisch onderzoek',
    keywords:
        'camaps faqs, camaps fx faqs, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'nl_NL',

    haveQuestion: 'Heeft u een vraag over CamAPS FX?',
    hereAnswers: 'Hier vindt u de antwoorden op een aantal veelgestelde vragen.',
    whatIsCamAPS: 'Wat is CamAPS FX?',
    whatIsCamAPSAnswer: 'CamAPS FX is een geavanceerde, gebruiksvriendelijke app voor het beheren van de glucosewaarden met behulp van een hybride closed-loop systeem bij personen vanaf 1 jaar en ouder met diabetes type 1.',
    isApproved: 'Is CamAPS FX goedgekeurd?',
    isApprovedAnswer: 'De app is uitgebreid getest tijdens klinische onderzoeken en is goedgekeurd in zowel het Verenigd Koninkrijk als in de EU.',
    isAvailable: 'Is CamAPS FX beschikbaar in mijn regio?',
    isAvailableAnswer1: 'CamAPS FX is verkrijgbaar in een aantal diabetesklinieken en voor mensen die zelf betalen en een online-training volgen. Raadpleeg uw kliniek of ga naar',
    isAvailableAnswer2: 'Bestellingen',
    isAvailableAnswer3: 'om de app te bestellen.',
    cost: 'Hoeveel kost CamAPS FX?',
    costAnswer1: 'Ga voor informatie over de kosten naar het gedeelte',
    costAnswer2: 'van de website.',
    compatibleCgm: 'Welke CGM’s zijn compatibel met CamAPS FX?',
    compatibleCgmAnswer: 'CamAPS FX is compatibel met de',
    compatibleCgmAnswer1: 'en de',
    compatibleCgmAnswer2: '. Klik op de links om de compatibele smartphones en besturingssystemen te bekijken.',
    whatPumps: 'Welke insulinepompen zijn compatibel met CamAPS FX?',
    whatPumpsAnswer1: 'CamAPS FX is compatibel met',
    whatPumpsAnswer2: 'mylife YpsoPump-, Dana Diabecare RS- en DANA-i-',
    whatPumpsAnswer3: 'insulinepompen.',
    whatPumpsAnswerHtml: 'https://advancedtherapeutics.org.uk/shop/dana-diabecare-insulin-pump/dana-i-insulin-pump',
    whatPhone: 'Welke smartphones zijn compatibel met CamAPS FX?',
    whatPhoneAnswer: 'CamAPS FX draait op Android-smartphones die worden ondersteund door uw CGM-fabrikant met OS 10 en hoger. Het werkt ook met FreeStyle Libre 3 op iOS 17 of 18, momenteel in Zweden en binnenkort wereldwijd beschikbaar.',

    whatPhoneAnswer1: '',
    whatPhoneAnswer2: 'Klik',
    whatPhoneAnswer3: 'op deze link voor een lijst met apparaten en besturingssystemen die compatibel zijn met',
    whatPhoneAnswerDexcom: 'de Dexcom G6-sensor en de Dexcom G6-app.',
    whatPhoneAnswerLibre3: 'de FreeStyle Libre 3-sensor en de FreeStyle Libre 3-app.' +
        '<p><p></p>' +
        'Vanaf 1 januari 2025, zal CamAPS FX alleen worden ondersteund op sensor-compatibele telefoons met Android besturingssystemen 10 en hoger. Gebruikers van besturingssystemen lager dan OS 10 kunnen CamAPS FX na deze datum niet meer bijwerken of opnieuw installeren.',
    whatPhoneAnswerDexcomHtml:'https://www.dexcom.com/compatibility',
    whatPhoneAnswerLibreHtml:'https://www.diabetescare.abbott/support/manuals.html',

    whereUpload: 'Waarheen kan ik mijn gegevens uploaden vanaf CamAPS FX?',
    whereUploadAnswer: 'Op dit moment kunnen gegevens vanaf de CamAPS FX worden geüploaded naar mylife Cloud en Glooko.',
    whatLanguage: 'Welke talen zijn er binnen CamAPS FX beschikbaar?',
    whatLanguageAnswer: 'CamAPS FX is beschikbaar in het Engels, Tsjechisch, Deens, Duits, Spaans, Frans, Italiaans, Nederlands, Pools, Fins en Zweeds.',
    whereResults: 'Waar vind ik de uitkomsten van de uitgevoerde klinische onderzoeken?',
    whereResultsAnswer1: 'Publicaties die betrekking hebben op het gebruik van het Cambridge-controle-algoritme zijn hier verkrijgbaar als download:',
    whereResultsAnswer2: ' ',
    whereResultsAnswer3: 'Belangrijke publicaties in',
    whereResultsAnswer4: 'en',
};
